.auth-landing-web {
    background: #1A1C20;
    /* width: 100%;
    height: 100% */
}
.data-section-main {
    padding: 5px;
    width: 330px;
}
.main-section-content {
    background: #1D2226;
    border-radius: 10px;
    padding: 10px;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
}
.icons {
    margin-top: 10px
}
.fantasy-heading {
    font-weight: 700;
    font-size: 49px;
    line-height: 40px;
    padding-top: 20px
}
.fantasy-img {
height: 118px;
}