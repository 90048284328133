.modal-content-dark{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    outline: 0;
    background-color: #1A1C20;
}

.modal-content-light{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    outline: 0;
    background-color: #1D2226;
}

.modal-fixed-height {
    height: 90vh;
    max-height: 90vh;
    overflow: hidden;
}

.table-striped > thead > tr > th {
    border-width: 0;
    border-top: 0.01em solid #C4C4C420;
}

.table-striped > tbody > tr > td {
    border-width: 1;
    border-top: 0.01em solid #C4C4C420;
}

th, tr {
    white-space: nowrap;
}