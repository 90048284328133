.float-input{
    /* padding: 16px !important; */
    gap: 8px !important;
    height: 56px;
    border-radius: 4px;
    color: white;
    width: 100%;
    background: #1A1A1A !important;
}

.f-form-group {
    position: relative;
    margin-bottom: 0.1rem;
  }
  
  .f-form-control {
    border: none;
    /* border: 1px solid #03F29E; */
    outline: none;
    padding: 0.5rem 0.5rem 0.25rem;
    font-size: 1rem;
    background-color: transparent;
    transition: all 0.1s;
  }
  
  .f-form-control-placeholder {
    position: absolute;
    top: 1rem;
    left: 0.5rem;
    font-size: 1rem;
    color: #9e9e9e;
    transition: all 0.3s;
    pointer-events: none;
  }

  .f-form-control-placeholder--invalid {
    position: absolute;
    top: 1rem;
    left: 0.5rem;
    font-size: 1rem;
    color: #DC3444;
    transition: all 0.3s;
    pointer-events: none;
  }
  
  .f-form-group.active .f-form-control-placeholder {
    font-size: 0.75rem;
    top: 0;
    padding-top: 5px;
    color: #03F29E;
  }

  .f-form-group.inserted .f-form-control-placeholder {
    font-size: 0.75rem;
    top: 0;
    padding-top: 5px;
    color: #9e9e9e;
  }

  .f-form-group.date .f-form-control-placeholder {
    font-size: 0.75rem;
    top: 0;
    padding-top: 5px;
    color: #9e9e9e;
  }
  
  .f-form-group.active .f-form-control {
    border: 1px solid #03F29E !important;
  }
  
  .f-form-group.error .f-form-control {
    border: 1px solid #DC3444 !important;
  }

  .f-form-group .f-form-control:focus ~ .f-form-control-placeholder {
    top: 0;
    font-size: 0.75rem;
    color: #03F29E;
  }
  
  /* .f-form-group .f-form-control:focus {
    border: 1px solid #03F29E !important;
  } */