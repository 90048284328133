/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* q
    Created on : Aug 8, 2018, 12:59:57 AM
    Author     : isolutions
*/
/* 
.sc-bxivhb{
  background: rgb(47, 62, 68);
}
*/
/*old gree color 2-5dea9*/

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

body {
  overflow-x: hidden;
  font-family: Barlow;
}

.picker {
  background: #343434;
  border: none;
  color: white;
  padding: 5%;
  border: 1px  solid rgb(138, 138, 138);
  border-radius: 2%;
  margin-right:4%;
  width: 130%;
}
.darkTimePicker > .form-control:disabled {
  background-color: #161B1E;
  color: grey;
}

.darkTimePicker {
  color: black;
}

.react-datepicker-wrapper > .react-datepicker__input-container > .disablePicker {
  background-color: #161B1E;
  color: grey;
  border: none;
  padding: 5%;
  border: 1px  solid rgb(138, 138, 138);
  border-radius: 2%;
  margin-right:4%;
  width: 130%;
}

.timePicker {
  color: black;
}

.progressbar {
  counter-reset: step;
  display: flex;
  justify-content: center;
  margin-left: 3rem;
  margin-right: 3rem;
}
.progressbar li {
  list-style-type: none;
  width: 20%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}
.progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 27px;
  border: 2px solid #03F29E;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: #03F29E;
  color: #343434;
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #343434;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: #03F29E;
}
.progressbar li.active:before {
  border-color: #03F29E;
}
.progressbar li.active + li:after {
  background-color: #03F29E;
}

.or {
  display:flex;
  justify-content:center;
  align-items: center;
}

.or:after,
.or:before {
    content: "";
    display: block;
    background: grey;
    width: 40%;
    height:1px;
    margin: 0 10px;
}

.darkText{
  color: #1C1E22;
}

.divOverlay{
    z-index: 100;
    position: fixed;
    bottom: 10px;
    /* margin: 20px; */
    border-radius: 10px;
    /* right: 10px; */
    /* left: 10px; */
    /* right: 10px; */
    /* left: 50%; */
    right: 0;
    background: #03F29E;
    color: #343434;
    max-width: 100%;
    width: 500px;
    /* text-align: center; */
    max-height: 300px;
}

.bigButton{
  width: 200px;
   height: 48px;
    font-size: 16px
}
.stickInput{
  /* margin-top: 15px; */
  /* background-color: #dfeeff; */
  /* padding: 10px; */
  height: calc(100vh - 55px)
}

.card-header{
  border: none;
}

.stickInputMobFocus{
  /* margin-top: 15px; */
  height: calc(100vh - 55px)
}

.stickInputMob{
  /* margin-top: 15px; */
  height: 90vh
  /* background-color: #dfeeff; */
  /* padding: 10px; */
}

.landingHeading{
  font-size: 50px
}

.card{
  /* box-shadow: rgba(45, 54, 59, 0.25) 0px 1px 3px; */
  background: #343434;
  border: 0.05rem solid #727272;
}

.btn-link{
  color: #429488;
}

.headerWrapper{
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 55px;
  background: #343434;
  z-index: 1024;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.10);
}

.secUl{
  max-width: 1140px;
  width: 100%;
  margin: auto;
}


.carousel.slide{
  margin-top: 10px
}

.chatMain1{
  height: 100%; 
  /* min-height: -webkit-fill-available; */
  background-color: white;
  margin: 0;
  border-radius: 0.25em;
}

.chatMain2{
  height: 100%; 
  /* min-height: -webkit-fill-available; */
  background-color: white;
  border-radius: 0.25em;
}

.chSumUser{
  font-size: 14px;
}
.chSumTime{
  font-size: 12px;
}

.hide-flex{
  display: flex;
}

.show-flex{
  display: none;
}

.chatImg{
  width: 40px;
}

.chatUser{
 font-size: 14px;
 font-weight: bold;
}

.button__badge {
  background-color: #fa3e3e;
  border-radius: 11px;
  color: white;
  padding: 1px 5px;
  font-size: 10px;
  position: absolute;
  top: 17px;
}

.chatFont{
  font-size: 14px;
}

.chatImgRM{
  margin-right: 0.5vw;
}

.greenText{
  color: #03F29E;
}

.landingCard{
  background: rgba(196, 196, 196, 0.2);
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.chatMobDrop{
  background: #03F29E;
  color: white;
  border: none;
}

.chatMobDrop:active{
 background-color:  #03F29E !important;
  color: white !important;
  border: none !important;
}

.chatMobDrop:focus{
  background-color:  #03F29E !important;
   color: white !important;
   border: none !important;
 }

.red-font{
  color:red;
}

.horizontal-slider {
  width: 100%;
  height: 20px;
  border-radius: 25px;
}

.example-track{
    top: 20px;
    height: 10px;
    background: rgb(221, 221, 221);
}

.example-track-1{
  background: rgb(221, 221, 221);
}

.example-track-2{
  background: #03F29E;
}

.example-thumb{
  /* font-size: 13px;
  text-align: center;
  background-color: rgb(39, 50, 56);
  color: white;; */
  /* cursor: pointer; */
  /* width: 24px; */
  /* height: 20px;
  border-radius: 14px; */
  top: -9px;
}

.example-thumb::before{
  font-family: FontAwesome;
  content: "\f276";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  font-size: 18px;
}


.horizontal-slider .example-track {
  top: 6px;
  height: 10px;
  border-radius: 16px;
  margin: 0 6px;
}

.example-thumb-1{
  display: none;
}


.font-12{
  font-size: 12px;
}
.fixedNav{
  position:fixed;
  top:55px;
  width:100%;
  z-index:100;
}
.chMobTab{
  height: 40px;
  background: rgb(39, 50, 56);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chBoxVl {
  border-left: 1px solid grey;
  height: 10px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
}

.mbb-5{
  margin-bottom: 5px;
}
.mtc-5{
  margin-top: 5px;
}

.bb0{
  border-bottom: none;
}
.p0{
  padding: 0;
}
.home_importl_btn{
  margin-right: 30px;
}

.contestIndexTop{
  margin-bottom: 20px;
}

.import_l_back{
  width: 200px;
  margin-right: 50px
}

.import_l_next{
  width: 200px;
}

.react_ul_count{
  margin-top: 10px
}

.react_ul_count li{

  display: inline-block;
  padding: 0;
  line-height: 0;
  border: 1px solid lightblue;
  border-radius: 5px;
  margin-right: 8px;
  padding: 3px

}

.react_ul_count > li > *{

  vertical-align: middle;
}

.react_ul li{

  display: inline-block;
  border-right: 1px solid #444;
  padding: 5px;
  line-height: 0;

}

.react_ul li:last-child{
  border: none;
}

.react_ul{
  padding:  0 !important
}

.chat_upload_media{
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: lightgrey;
}

.col-1-5 {
  flex: 0 0 12.3%;
  max-width: 12.3%;
  min-width: 222px;
}

.strike {
  display: block;
  text-align: center;
  /* overflow: hidden; */
  white-space: nowrap; 
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background:lightgrey;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

.msg_pop_up{
  visibility: hidden;
  position: absolute;
  top: -30px;
  right: 3%;
  background: white;
  border: 1px solid #444;
  padding: 2px;
  border-radius: .25rem
  }

.msg_content_box{
  padding: 8px
}
.msg_content_box:hover{
  background: 	#343434;
  position: relative
}

.msg_content_box:hover .msg_pop_up{
  visibility: visible;
}

.col1{
  flex: 0 0 1.33333%;
  max-width: 4%;
}

.felxContainer{
  display: flex;
  flex-flow: column nowrap;
}

.felxContainer > :first-child {
  margin-top: auto !important;
  /* use !important to prevent breakage from child margin settings */
}

.innerChat{
  overflow-y: auto;
  overflow-x: hidden;
}

.style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px gray;
	background-color: #F5F5F5;
}

.style-3::-webkit-scrollbar
{
	width: 6px;
  height: 6px;
	background-color: #F5F5F5;
}

.style-3::-webkit-scrollbar-thumb
{
	background-color: gray;
}

.chatLeftScroll{

  overflow-y: scroll;
  height: 100%;
}

.sc-VigVT{
  font-weight: bold;
  color: black
} 

.my_team_tab th{
  border: 1px solid whitesmoke;
  padding: 7px
}

.team_name{
  width: 100px;
}


.mt-80{
  margin-top: 75px
}

.mt-55{
  margin-top: 55px
}

.dropdown_hover:hover .dropdown-menu {
  display: block;
  }

  .dropdown-menu{
    width: max-content
  }
  
.carousel-inner {
  max-height: 90vh;
  border-radius: 10px
}

/* .carousel-inner .carousel-item {
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  display: block;
  position: absolute;
  }
  .carousel-inner .carousel-item:first-of-type {
  position: relative;
  }
  .carousel-inner .active {
  opacity: 1;
  } */

.border-none{
  border: none
}

.brand-card-body > *:not(:last-child) {
  border-right: none;
}

.hide-576{
  display: block
}
.mt-dynamic{
  margin-top: 20px;
}

.mt-chat-dynamic{
  margin-top: 20px;
}

.hide-576-ib{
  display: inline-block
}

.hide-576-td{
  display: table-cell
}

.show-576-td{
  display: none
}

.show-576{
  display: none
}

.show-576-flex{
  display: none;
}

.show-576-ib{
  display: none;
}

.cardMargin{
  margin: 25px
}

.lp_btn{
  margin-right: 20px
}

.commissioner_index a{
  color: #06c
}

.commissioner_index a:hover{
  color: #004d9a
}

.default_a{
  color: #03F29E
}

.default_a:hover{
  color: #03F29E
}

.flex1{
  flex: 1
}

.silver-bg{
  background: rgb(250, 250, 250)
}
.home_upper_div{
  /* border: 2px solid black; */
}

.home_welcome{
  display: inline-block;
  margin-left: 10px;
  margin-top: 5px
}

.home_btn_box{

  text-align: right;
  /* vertical-align: top; */
  /* padding: 0 44px; */
  padding-top: 18px;

}

.footerDiv a{
  color: #222
}

.footerDiv li{
  color: #222
}


.footerDiv a:hover{
  color: #222
}


.headerLink a{
  color: #222
}

.headerLink a:hover{
  color: #222
}

.slide1_heading{
  font-size: 50px
}
.slide1_sub_heading{
  font-size: 30px
}

.slide2_heading{
  font-size: 70px
}
.slide2_sub_heading{
  font-size: 30px
}

.carousel-content {
  position: absolute;
  bottom: 50%;
  left: 10%;
  z-index: 20;
  color: white;
  text-shadow: 0 1px 2px rgba(0,0,0,.6);
}

/* .carousel-item:after {
  content:"";
  display:block;
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background:rgba(0,0,0,0.7);
} */

.carousel-caption {
  bottom: 5%;
  color: #03F29E;
  font-size: 30px;
  text-shadow: 0 1px 2px rgba(0,0,0,.6);

}
.carousel-btn{
  position: absolute;
  bottom: 25%;
  left: 77%;
  z-index: 20;
}

.carousel-btn button{
  width: 200px;
  font-size: 22px;
  text-shadow: 0 1px 2px rgba(0,0,0,.6);
}

.carousel-partner{
  position: absolute;
  bottom: 80%;
  left: 77%;
  z-index: 20;
  text-shadow: 0 1px 2px rgba(0,0,0,.6);
}


a{
  color: white
}
a:hover{
  color: white
}
.is-danger{
  border: 1px solid #f86c6b
}
.react-autosuggest__container {
  position: relative;
}

.resp-table{
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.resp-tbody{
  display: table;
  width: 100%;
}

.sidebar .nav-link:hover{
  background: #343434
}

html:not([dir="rtl"]) .sidebar {
  margin-left: -300px;
}
.sidebar{
  background: #343434
}
.sidebar .nav {
  width: 300px;
}
.sidebar .sidebar-nav {
  width: 300px;
}
.resp-thead{
  display: table;
  width: 100%;
}

.default-btn{
  width: 200px;
}

.contest_table td{
  padding: 0.75em 0;
}

.green-font{
  color: #03F29E
}

.react-autosuggest__input {
  width: 100%;
  max-height: 37px;
  height: 100%;
  padding: 10px 20px;
  /* font-family: Helvetica, sans-serif; */
  /* font-weight: 300; */
  border: 1px solid #6c757d;
  border-radius: 4px;
  background: #343434;
  color: #ffff;
}

.react-autosuggest__input_danger {
  width: 240px;
  height: 38px;
  padding: 10px 20px;
  /* font-family: Helvetica, sans-serif; */
  /* font-weight: 300; */
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #f86c6b !important
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}


.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #333D44;
}


.padding-10 {
    
    padding:10px;
    
}
.list-hover:hover{
    background : #95AFBA;    
    cursor: pointer;
    color: white;
}

.player-list{
    /* background:#273238; */
    background:white; 
    color : black;
    /* width:50%; */
 }

 .dynamic-scrollbar{
    overflow-y: scroll;
    height:calc(85vh - 40px);
 }

 .player-list-scroll{
    overflow-y: auto;
    height:calc(85vh - 0px);
 }

 .align-middle{
     vertical-align: middle;
 }

 .align-top{
  vertical-align: top;
}

.padding-20 {
    
    padding:20px;
}

.image-upload > input
{
    display: none;
}

.image-upload i
{
    cursor: pointer;
}


.lineup-table-row-main {
    min-height: 75px;
}
.center{
    text-align: center;
}

.lineup-table-head {
    background: #03F29E;
    color: white;
}
#game-log-table > thead > tr > th{
    border: 1px solid grey
}
.main-color-text{
 color: #03F29E;
}
.main-color-bg{
    background: #03F29E;
}
   

.lineup-table-upper-head {
    background: #273238;
    color: white;
}

.lineup-table-img{
    width: 50px;
}

.lineup-table-row{
    height: 60px;
   cursor: pointer;

}

.table-padding td{
    padding: 8px
}

.table-padding th{
  padding: 8px
}

.pointer{
    cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.lineup-table-img-big{
     height: 160px;
     vertical-align: baseline;
     margin-right: 25px;

}
.lineup-background-img{
    background: #273238;
    color:white;
}

.msgBackground{
  background: rgba(255, 255, 255, 0.1);
  color: #A2A2A2;
  border-radius: 22px;
  padding: 0 5px;
}

.msgBackgroundLeft{
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 14px;
  padding: 3px 10px;
  max-width: 70%;
  display: inline-block;
}

.msgBackgroundRight{
  background: #5FAEF2;
  color: #fff;
  border-radius: 14px;
  padding: 3px 10px;
  max-width: 70%;
  display: inline-block;
  text-align: left;
}

.msgTime{
  color: "#828282";
  font-size: 11px;
}

.mainDiv{
    margin-top: 20px;
}
.salary-league{
    font-weight: bold;
    font-size: 20px;
    float: right;
}
.stat-td{
    font-size: 20px;
}
.table-body{
    font-size: 15px;
    background:white;
    color:black;
}

.lineup-stat{    
    font-size: 16px;
    font-weight: bold;
     /* color:#C6C6C6; */
}

.upperStatCenter {
  text-align: center;
  /* padding-left: 70px; */
  /* padding-right: 60px; */
}

.lineup-stat-number{    
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0rem;
}

.bold{
    font-weight:bold;
}
.font-16{
  font-size: 16px
}

.padding-30{
    
    padding:30px;
    
}

.color-grey{
    color:#C6C6C6;
}

.ball-clip-rotate > div {
    border: 2px solid black;
    border-bottom-color: transparent;
  }

  .ball-pulse > div {
    background: #03F29E
  }

  .draft-button{
      text-align: center;
      margin-top: 20%;
  }

  .draft-button button{
   width: 210px;
   margin-top: 10px;
}

.navLink{
    color:white!important;
    /* margin-right: 30px!important; */
    /* font-size: 15px; */
}
.navLink > a {
    color:white!important;
}

.navLink:hover{
    background-color: #03F29E;
}

.inner-nav{
    /* width: 100vw;
    position: relative;
    margin-left: -50vw;
    height: 100px;
    left: 50%; */
    margin-bottom: 20px;
}

.dreamteam_league_thumbnail{
    width: 60px;
    display: inline;
    float: left;
    margin-right: 12px;
}

.player-list-main-header{
   padding: 10px;
   text-align: center;
   background: #273238;
    color: white;
    margin-top: 1px;
}
.player-list-bottom-values{
    padding: 10px;
    text-align: center;
    border-top: 1px solid #ccc;
    border-width:thin;
    border-collapse: collapse;
}

.player-list-bottom-header{
    padding: 10px;
    text-align: center;
    color: white;    
}
.player-list-bottom-header > div > span {
    font-weight: bold;
}

.left-stats{
    /* margin: 0 20px; */
    text-align: center;
}

.lineup-total{
    font-size: 20px;
     font-weight: bold;
}
.color-transition-up{
    animation: change 3s step-end both;        
}
@keyframes change {
    from { color: green}
    to   { color: black }
}

.color-transition-down{
    animation: red_to_black 3s step-end both;        
}
@keyframes red_to_black {
    from { color: red}
    to   { color: black }
}
.scorebaord-players{
    color: gray;
    font-size: 14px;
}

.blink_me {
    animation: blinker 0.5s 3;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
.standing-table{
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.activecustom{
    color: white!important;
    background: #273238;
    border-color:lightgray!important;
    border-bottom-color: lightgray!important;
}
.padding-25{
    padding: 16px 25px;
}

.player-list-text{
    text-align: center;
    /* margin: auto; */
    /* height: 100%; */
    margin-top: 20%;
}
.check-icon{
    color: green;
}

.green-bg{
    background: #03F29E;
    color: white
}
.dark-btn{
  background: rgb(39, 50, 56);
  color: white;
}

.sidebar-btn{
  background: rgba(66, 148, 136, 0.15);
  border: 1px solid #429488;
  border-radius: 17px;
  font-size: 12px;
  color: #429488;
  padding: 0px 9px 0px 10px;
}

.revertColor{
  background: #343434;
  color: #D2D2D2
}

.chatSearch > input{
  background: #343434;
  color: #D2D2D2;
  border: 1px solid #444;
}

.chatSearchMain > input{
  background: rgba(255, 255, 255, 0.05);
  color: #D2D2D2;
  border: 1px solid #444;
}

.chatSearchMain > input:focus{
  background: rgba(255, 255, 255, 0.05);
  color: #D2D2D2;
  border: 1px solid #444;
}

.chatSearch > input:focus{
  background: #343434;
  color: #D2D2D2;
  border: 1px solid #444;
}

.createChatMain{
  display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
}

.tag{
  background: rgba(66, 148, 136, 0.15) !important;
  border-radius: 17px;
  font-size: 12px;
  color: #429488;
  padding: 0px 9px 0px 10px;
}

.h2h-tag{
  color: #CC7D44;
  background: rgba(204, 125, 68, 0.25);
  border-radius: 17px;
  font-size: 12px;
  padding: 0px 9px 0px 10px;
}

.nav-link {
  display: block;
  padding: 0.5rem .75rem;
}

.tab-content{
  border: none;
}

.nav-tabs{
  background-color: rgb(39, 50, 56);
  color: white;
  font-size: 18px
}

.nav-tabs .nav-link {
  border-radius: 0;
  border-left: 1px solid white
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  /* border-right: 1px solid; */
  border-radius: 0;
  /* border-left: 1px solid white; */
  /* background-color: #fff; */
  /* color: #03F29E !important */
}

.nav-tabs .nav-link.active {
  /* color: #fff; */
  /* background-color: #03F29E; */
  font-weight: bold;
  color: #03F29E
}

.nav-tabs .nav-link.active:hover {
  font-weight: bold;
  color: #03F29E
}

.card-header{
  background-color: rgb(39, 50, 56);
  color: white;
}

.white-btn{
  background: white;
  color: #03F29E;
}

.white-btn:hover{
  background: white;
  color: #03F29E;
}

.witdth-200{
  width: 200px;
}

.sticky-heading{
  padding-left: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  width: fit-content;
  margin: 0 auto;
  background: white;
  border-radius: 10px;
}

.submit-lineup-btn{
    margin-left: 45%;
    margin-top: 20%;
    font-size: 27px;
    background-color: #273238;
    color: white;
}
.submit-lineup-btn:hover{
    background-color: #273238;
    color: white;
}

.draft-btn{
    background-color: #273238;
    color: white;
}
.draft-btn:hover{
    background-color: #273238;
    color: white;
}
.green-btn{
    background-color: #03F29E;
    color: #273238;
    border:none;
}

.green-btn-outline{
    color: #03F29E;
    background-color: transparent;
    background-image: none;
    border-color: #03F29E;
}

.green-btn-outline:hover{
  color: #273238;
  background-color: #03F29E;
  border-color: #03F29E;
}

.liveBoxChatBtn{
  color: white;
  background-color: transparent;
  background-image: none;
  border-color: #03F29E;
}

.liveBoxChatBtn:hover{
  color: white;
  background-color: #03F29E;
  border-color: #03F29E;
}

.green-btn:hover{
    opacity: 0.9;
    background-color: #03F29E;
    color: #273238;
    border:none;
}

.favPlayerBottom{
  background: rgb(255, 255, 255);
  text-align: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.favPlayerPosMain{
      margin-top: -15px;
      margin-bottom: 8px;
      font-size: 12px;
      font-weight: bold;
}
.rivalBox{
  height: 115px;
}

.favPlayerPosLeft{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.favPlayerPosRight{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dark-btn:hover{
  opacity: 0.9;
  background: rgb(39, 50, 56);
  color: white;
}

.profileHeader{
  background: #343434;
  color: #fff
}

.favPlayer{
  width: 100%;
  border-radius: 10px;
}

.dark-font{
  color: rgb(39, 50, 56);;
}


.teamNavBtn:focus{
    background-color: white;
}

.teamNavBtn:active{
  background-color: white;
}

.form-control {
    font-size: 16px;
  }

.mt-20{
    margin-top: 20px;
}
.mtt-5{
  margin-top: 5px;
}

.mt-60{
    margin-top: 60px
}
.m-100{
    margin : 100px
}
#cursor-pointer{
    cursor: pointer;
}

.lh-point{
    font-weight: bold;
    font-size: 18px;
}
.lh-stat-string{
    display: block;
    font-size: 13px;
}

#lh-opp{
    font-size: 15px;
}
#lh-opp-date{
    padding-right: 12px;
     font-size: 12px;
}

#player_name_short{
    display: none;
}
#player_name{
    display: block;
}
#lh-standing-row{
    height: 65px;
}
#l-tl-div{
    color: white;
    background: rgb(39, 50, 56);
    height: 55px;
    /* padding: 14px 0px; */
    font-size: 14px;
    margin-bottom: 1px;
    padding: 7px;
}
#l-tr-div{
    background: rgb(39, 50, 56);
    padding: 8px;
    height: 55px;
}
.dark-blue-bg{
    background: rgb(39, 50, 56);
    color: white;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.block{
  display: block
}

.box .ro.header {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .ro.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 15px; */
  /* overflow-y: scroll; */
  /* height: 60vh; */
}

.box .ro.footer {
  flex: 0 1 40px;
}

.memb-hover:hover{
  background-color: lightgreen
}

.text-login{
  background-color: #03F29E;
  border: 1px solid white;
  color: white
}

.my_account_header p{
  margin-bottom: 0;
}
.my_account_header{
  margin-bottom: 30px;
  border: 3px solid #03F29E;
  padding: 15px
}
.my_account_list li{
  margin: 5px 0;
}

.friend_btn{
  display: block; margin: 0 auto;
  width: 200px;
}

.text-login:focus{

  background-color: #03F29E;
  border: 1px solid white;
  color: white
}

.text-login::placeholder{
  color: white;
}

.login-btn{
  border: 1px solid white;
  color: white
}

.login-btn:hover{
  background-color: #03F29E;
  border: 1px solid white;
  color: white;
}

.login-btn:active{
  background-color: #03F29E !important;
  border: 1px solid white !important;
  color: white !important;
}

.nav-link2{

  color: white !important;
  font-weight: bolder !important
}

.upload-img {
  max-width: 100%;
  max-height: 100%;
}

.padding-lr{
    padding-left: 0px;
    padding-right: 0px;
}
.alice-carousel{box-sizing: border-box; position: relative; width: 100%; margin: auto; background: rgb(39, 50, 56); color: white; margin: 5px 0; padding: 0 3px;}

#lineup-main-div{
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

#lineup-lower-btm{
    padding-left: 0;
    padding-right: 3px;
    border-top: 1px;
}
#lineup-wrapper{
    margin-top: -10px;
}

.col4{
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}
.col8{
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.show{
    display: block;
}

.hide{
    display: none;
}

.inline-block{
    display: inline-block
}

.pdt-20{
  padding-top: 20px
}

.pd-20{
  padding: 20px 20px 0 0;
}

.pd-10{
  padding: 10px;
}

.mr-lr-3{
 margin-right: 17px
}

.team-players td{ 

    padding: .15rem;
    vertical-align: middle;

}

.mrb-5{
  margin-bottom: 5px
}

.col6{
    flex: 0 0 50%;
    max-width: 50%;
}
#single-player-name{
    font-size: 30px;
    display: block;
}
.single-stats{
    padding-top: 50px;
    padding-bottom: 0px;
    overflow: scroll;
}
#single-stats-div{
    min-width: 600px;
}
#player-list-div{
    background: white;
}

.tabs{
    background: white;
    color: black;
    width: 100%;
    display: table 
}

#game-log-table > thead > tr > th{
    padding: 4px
}

.roundLoader {
  border: 2px solid #343434; /* Light grey */
  border-top: 2px solid #03F29E; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.lh-salary{
  color: grey;
  font-size: 12px
}
.lh-apw{
    font-size: 12px
}
#standing-week{
  display: none;
}
.standing-table{
    display: inline-table;
}

.img-player{
display: block;
max-width: 50px;
max-height: 50px;
width: auto;
height: auto;
}
.img-container{
    position: relative;
    text-align: center;
    color: black;
    margin-left: 10px;
}
/* Top left text */
.top-left {
    position: absolute;
    top: -5px;
    left: -11px;
    font-size: 13px;
  }
  .big-position{
    font-size: 16px;
    text-align: center;
  }
  .margin0{
      margin : 0;
  }
  .float-left{
      float: right;
  }
  .saved-lineup-username{
      font-size: 16px;
  }
  .league-type-card{
      border: 1px solid lightgray;
      text-align: center;
      cursor: pointer;
  }
  
  .league-type-card:hover{
      opacity: 0.8;
  }

  ol.progtrckr {
    list-style-type: none;
    padding: 0;
    font-size: 18px;
  }
  
  ol.progtrckr li {
    display: inline-block;
    text-align: center;
    line-height: 67px;
    cursor: pointer;
  }
  
  ol.progtrckr li span {
    padding: 0 1.5rem;
  }
  
  @media (max-width: 650px) {
    .progtrckr li span {
      display: none;
    }
  }
  .progtrckr em {
    display: none;
    font-weight: 700;
    padding-left: 1rem;
  }
  
  @media (max-width: 650px) {
    .progtrckr em {
      display: inline;
    }
  }
  
  ol.progtrckr li.progtrckr-todo {
    color: silver;
    border-bottom: 4px solid silver;
  }
  
  ol.progtrckr li.progtrckr-doing {
    color: black;
    border-bottom: 4px solid #CCCCCC;
  }
  
  ol.progtrckr li.progtrckr-done {
    color: black;
    border-bottom: 4px solid #03F29E;
  }
  
  ol.progtrckr li:after {
    content: "\00a0\00a0";
  }
  
  ol.progtrckr li:before {
    position: relative;
    bottom: -3.7rem;
    float: left;
    left: 50%;
  }
  
  ol.progtrckr li.progtrckr-todo:before {
    content: "\039F";
    color: silver;
    background-color: white;
    width: 1.2em;
    line-height: 1.4em;
  }
  
  ol.progtrckr li.progtrckr-todo:hover:before {
    color: #ff4500;
  }
  
  ol.progtrckr li.progtrckr-doing:before {
    content: "\2022";
    color: white;
    background-color: #CCCCCC;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
  }
  
  ol.progtrckr li.progtrckr-doing:hover:before {
    color: #ff4500;
  }
  
  ol.progtrckr li.progtrckr-done:before {
    content: "\2713";
    color: white;
    background-color: #03F29E;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
  }
  
  ol.progtrckr li.progtrckr-done:hover:before {
    color: #333;
  }

  .col-centered{
    float: none;
    margin: 0 auto;
}
.mt-40{
    margin-top: 40px
}
.mt-30{
  margin-top: 30px
}
.mb-40{
    margin-bottom: 40px
}
.m-80{
    margin: 80px
}
.mb-10{
    margin-bottom: 10px
}
.mt-10{
  margin-top: 10px
}

.mt-10-percent{
  margin-top: 5%
}

.mb-0{
  margin-bottom: 0;
}
.mbb-3{
  margin-bottom: 3px
}

.mbb-8{
  margin-bottom: 8px
}

.row-normal{

  margin-left: 0;
  margin-right: 0;
}

.vt{
  vertical-align: top
}

.sl-sb-score{
  font-weight: bold;
  font-size: 26px
}
.sb-box td{
  vertical-align: middle;
}

.sb-box{
  border: 1px solid lightgray;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn-width{
  width: 200px
}

.mb-20{
  margin-bottom: 20px
}

.offline-draft{
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  margin: 15px
}
.bracket-box{
  border: 1px solid lightgray;
    width: 100px;
    text-align: center;
    margin: 15px
}

.underline{
  text-decoration: underline
}

    /* Drag n drop css starts here */
  
  .card-container{
    padding: 10px;
    margin: 5px;
    background-color: #f3f3f3;
    box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 1px 1px rgba(0,0,0,0.24);
  }
  
  .currencyinput {
    border: 1px inset #ccc;
}
.currencyinput input {
    border: 0;
}
 
  
  .card-column-header{
    font-size: 18px;
  }
  
  .column-drag-handle{
    cursor: move;
    padding: 5px; 
  }
  
  .card-ghost {
    transition: transform 0.18s ease;
    transform: rotateZ(5deg)
  }
  
  .card-ghost-drop{
    transition: transform 0.18s ease-in-out;
    transform: rotateZ(0deg)
  }

  .invalid-feedback-custom{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f86c6b;
  }

  .form-radio
{
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     display: inline-block;
     position: relative;
     background-color: #f1f1f1;
     color: #666;
     top: 10px;
     height: 30px;
     width: 30px;
     border: 0;
     border-radius: 50px;
     cursor: pointer;     
     margin-right: 7px;
     outline: none;
}
.form-radio:checked::before
{
     position: absolute;
     font: 13px/1 'Open Sans', sans-serif;
     left: 11px;
     top: 7px;
     content: '\02143';
     transform: rotate(40deg);
}
.form-radio:hover
{
     background-color: #f7f7f7;
}
.form-radio:checked
{
     background-color: #f1f1f1;
}

.lbox{
    font-size: 16px;
}

.edit-link{
    font-size: 13px;
}

.padding-0{
    padding: 0;
}

.default-font{
  font-size: 16px
}

.lg_type_img{
  width: 250px;
  height: 250px;
}

.sl-lg-name{
  color: white;
  line-height: 35px;
  font-size: 18px;
}

.helmet{
    width: 77px;
    display: block;
    margin: 0px auto;
    padding-top: 9px;
}

.sl-draft-player{
  width: 77px;
  display: block;
  margin: 0px auto;
  /* margin-top: 14px; */
}

body > tr {
  display: table !important;
}

.importImg{
  width: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.landingPageHeader{
    font-size: 16px;
    background-color: white;
    justify-content: normal;
    border: none;
    background: transparent;
    position: inherit !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.10);

}

.normalHeader{
    font-size: 16px;
    background-color: transparent;
    color: white;
    border: none;
}

.dFont{
  font-size: 16px;
}
.land_p{
  font-size: 18px;
}

.landContestCardParent{
  margin: 0 10%;
}

.landContestCard{
  background: white;
  border-radius: 15px;
}

.landContestCardTop{
  background: #273238;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 5px;
}

.landTopText{
  color: white;
  margin: 200px 0 60px 0;
}

.landTopTextAbout{
  color: white;
  margin: 85px 0 60px 0;
}

.landHeading{
  font-size: 60px;
  font-weight: bold;
}

.landSubHeading{
  font-size: 45px;
  font-weight: bold;
}

.joinContestBtn{
    width: 300px;
    height: 60px;
    background: #FF8C72;
    border-radius: 6px;
}

.joinContestBtnMob{
  /* width: 130px; */
  background: #FF8C72;
  border-radius: 6px;
}

.contestText{
  color: #03F29E
}
.joinText{
  color: #fff;
  font-size: 22px;
}

.winImage{
    width: 75%;
    margin-left: auto;
    float: right
}

.whiteLogo{
  display: inline;
}

.greenLogo{
  display: none;
}

.googleBtn{
  background: #4286F5;
  border-radius: 30px;
  width: 100%;
  height: 48px;
  color: #fff;
  padding: .25rem;
  font-size: 16px;
  border: 0px;
}
.appleBtn {
    background: black;
    border-radius: 30px;
    width: 100%;
    height: 48px;
    color: #fff;
    padding: .25rem;
    font-size: 16px;
    border: 1px solid #fff;
}

.loginBtn{
  background: #000000;
  border-radius: 30px;
  width: 100%;
  height: 48px;
  color: #fff;
  padding: .25rem;
  font-size: 16px;
  border: 1px solid #fff;
}

.yahooBtn{
  background: #6002D2;
  border-radius: 30px;
  width: 294px;
  height: 48px;
  color: #fff;
  padding: .25rem;
  font-size: 16px;
}

.espnBtn{
  background: #DD0000;
  border-radius: 30px;
  width: 294px;
  height: 48px;
  color: #fff;
  padding: .25rem;
  font-size: 16px;
}

.cbsBtn{
  background: #0055A6;
  border-radius: 30px;
  width: 294px;
  height: 48px;
  color: #fff;
  padding: .25rem;
  font-size: 16px;
}

.fbBtn{
  background: #3B5999;
  border-radius: 30px;
  width: 294px;
  height: 48px;
  color: #fff;
  padding: .25rem;
  font-size: 16px
}

.emailBtn{
  background: #273238;
  border-radius: 30px;
  width: 294px;
  height: 48px;
  color: #fff;
  font-size: 16px
}

.greenBtn{
  background: #03F29E;
  border-radius: 30px;
  width: 294px;
  height: 48px;
  color: #fff;
  font-size: 16px
}

.multiBox{
  background: rgba(66, 148, 136, 0.75)
}

.fiftybox{
  background: rgba(197, 62, 62, 0.75)
}

.statBox{
  border-right: 2px solid rgba(255, 255, 255, 0.15);
}

.statBoxLeft{
  border-left: 2px solid rgba(255, 255, 255, 0.15);
}

.badgeContainer{
  display: inline-block;
  position: relative;
}

.newBadge{
  position: absolute;
  top: -18px;
  right: -60px;
  background: #FF8C72;
  padding: 3px 10px;
  border-radius: 16px;
  font-weight: bold;
  font-size: 12px;
  color: white;
}

.newBadgeMob{
    position: absolute;
    top: -8px;
    right: -25px;
    background: #FF8C72;
    padding: 0px 7px;
    border-radius: 16px;
    font-weight: bold;
    font-size: 10px;
    color: white;
}

.newBadgeContest{
  position: absolute;
  top: -22px;
  right: -30px;
  background: #FF8C72;
  padding: 3px 10px;
  border-radius: 16px;
  font-weight: bold;
  font-size: 12px;
  color: white;
}

.roundChat {
  position: relative;
}

.roundChat label {
  background-color: #343434;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  position: relative;
  width: 20px;
}

.roundChat label:after {
  border: 2px solid #343434;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 12px;
}

.roundChat input[type="checkbox"] {
  visibility: hidden;
}

.roundChat input[type="checkbox"]:checked + label {
  background-color: #5FAEF2;
}

.roundChat input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.circle {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
 }

 .avatar{
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
 }

 .roundImg{
  /* width:  50px; */
  /* height: 50px; */
  /* object-fit: cover; */
  /* border-radius: 50%; */
  vertical-align: middle;
  width: 60px;
  height: 60px;
  /* border-radius: 50%; */
  image-rendering: -moz-crisp-edges;         /* Firefox */
  image-rendering:   -o-crisp-edges;         /* Opera */
  image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
 }

 .roundImgSmall{
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #03F29E
 }

 .roundImgSmallNoOutline{
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
 }

 .roundImgMed{
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #03F29E
 }

 .roundImgMedNoOutline {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
 }

 .roundImgBig{
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
 }
 
/* .linkDark{
  color: rgb(31, 55, 91)
}

.linkDark:hover{
  color: rgb(31, 55, 91)
} */

.greeFont{
  color: #03F29E
}

.modalClose{
  position: absolute;
  top: 15px;
  font-size: 25px;
  right: 30px;
  z-index: 999
}

.modalCloseSmall{
  position: absolute;
  top: 10px;
  font-size: 18px;
  right: 15px;
  z-index: 999
}

.disableDiv {
  pointer-events: none;
  opacity: 0.5;
}

.modalBack{
  position: absolute;
  top: 15px;
  font-size: 25px;
  left: 30px;
}

.border{
  border: "1px solid #c8ced3";
  border-radius: 0.25rem;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}
.separator::before, .separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #bdbdbd;
}
.separator::before {
  margin-right: 1em;
}
.separator::after {
  margin-left: 1em;
}
.dynamicAlign{
  text-align: right;
}
.topRank{
  background: #273238;
  color: white;
  display: flex;
  justify-content: space-around;
  text-align: center;
  border-radius: 3px;
}

.topRank > div > span{
  font-size: 18px;
    font-weight: bold;
}
.respDivImageUpcoming{
  width: 105px;
  height: 105px;
}
.respDivImage{
  width: 90px;
  height: 90px;
}

.respDivImageLobby{
  width: 80px;
  height: 80px;
}

.h2hBox{
  font-weight: bold;
  font-size: 18px
}
.h2hImg{
  width: 40px;
}
.h2hOverlay{
  background: rgba(255, 147, 68, 0.75)
}
.contestHomeImg{
  border-radius: 50%;
  width: 50px;
}

.landBrand{
  width: 65px;
}

.textVisible{
  background: rgba(33, 33, 33, .5);
  color: white;
  font-weight: bold
}

.nflHeading{
  font-style: italic;
  font-size: 30px
}
.leadTable > tr > td{
  padding: 0.5rem !important
}

.yahooBox{
  padding: 5px 8px;
  background: #3e0083;
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  font-weight: bold;
}

.espnBox{
    padding: 5px 8px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    background: #cf2127;
}

.cbsBox{
  padding: 5px 8px;
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  background: #0055a6;
}

.userPointGreen{
  margin-right: 5px;
  font-size: 24px;
  color: #03F29E;
  font-weight: bold;
  vertical-align: middle
}

.userPointRed{
  margin-right: 5px;
  font-size: 24px;
  color: #F76A6A;
  font-weight: bold;
  vertical-align: middle
}

@media (max-width: 1300px) { 
  .h2hImg{
    width: 25px;
  }
  .contestBlock{
    font-size: 12px
  }
}

 /* Extra large devices (large desktops, 1200px and down) */
@media (max-width: 1200px) { 
  .landTopText{
    color: white;
    margin: 0;
  }

  .landTopSection{
    margin-bottom: -45%;
  }

  .winImage{
    width: 70%;
    margin-left: auto;
    float: right
  }
  .landHeading{
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 2px
  }
  .landSubHeading{
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 2px;
  }
  .nflHeading{
    font-style: italic;
    font-size: 24px
  }
}

/* Large devices (desktops, 992px and down) */
@media (max-width: 992px) { 
  .app-headerLanding {
    z-index: 1020;
    width: 100%;
    text-align: center;
    background-color: #fff;
  }
  .landAppBody{
    margin-top: 0;
  }
  .land_p{
    font-size: 14px !important;
  }
  .nflHeading{
    font-style: italic;
    font-size: 18px
  }
  .sidebar{
    width: 300px;
  }

}

/* Medium devices (tablets, 768px and down) */
@media (max-width: 768px) { 
  .landHeading{
    font-size: 30px !important;
    font-weight: bold;
    letter-spacing: 0 !important
  }
  .landSubHeading{
    font-size: 36px !important;
    font-weight: bold;
    letter-spacing: 0 !important
  }
  .contestHomeTop{
    font-size: 12px
  }
  .dynamicAlign{
    text-align: center
  }
  .respDivImage{
    width: 80px;
    height: 80px;
  }
  .respDivImageUpcoming{
    width: 80px;
    height: 80px;
  }
  .respDivImageLobby{
    width: 70px;
    height: 70px;
  }
  .h2hBox{
    font-weight: bold;
    font-size: 16px
  }
  .contestHomeImg{
    border-radius: 50%;
    width: 40px;
  }
  .landBrand{
    width: 50px;
  }
  .landContestCardParent{
    margin: 20px 0 10px 0;
  }
  .nflHeading{
    font-style: italic;
    font-size: 16px
  }
 }

/* Small devices (landscape phones, 576px and down) */
@media (max-width: 576px) { 
  .landBrand{
    width: 45px;
  }
  .joinText{
    color: #fff;
    font-size: 16px;
  }
  .bigButton{
    height: 48px;
    width: 100%;
    font-size: 16px;
  }
 }


      /* Drag n drop css ends here */
  
    /* *************************************************************************************************************************************** */

.navbar-toggler-icon{
  height: 23px !important;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='1.125' x2='18' y2='1.125' stroke='%23F0F3F7' stroke-width='1.75'/%3E%3Cline y1='7.125' x2='18' y2='7.125' stroke='%23F0F3F7' stroke-width='1.75'/%3E%3Cline y1='13.125' x2='18' y2='13.125' stroke='%23F0F3F7' stroke-width='1.75'/%3E%3C/svg%3E%0A") !important;
}

/* league home media queries start here  */

@media only screen and (max-width: 1100px) {
    #week-text {
        display: none;
    }
    #total-lineup-text{
        float: left;
    }
}

@media only screen and (max-width: 1289px) {
    #lh-lineup {
        flex: 0 0 100%;
        max-width: 100%;
    }
    #lh-standing{
        flex: 0 0 100%;
        max-width: 100%;
    }

}

@media screen and (max-width: 640px) {
    #lh-lineup > table > tbody, thead {
      font-size: 12px;
    }

      #lh-lineup table td, th {
        padding: 1px;
      } 

      .lh-salary{
        font-size: 10px
      }

      .lh-apw{
        font-size: 10px
    }

      #lineup-main-div table td, th {
        padding: 1px;
      } 

      #lh-standing > table > tbody, thead  {
        font-size: 12px;
    }

    #lineup-table > tbody, thead  {
        font-size: 12px;
    }

    /* body {
        font-size: 12px;
      } */
      .lh-point{
        font-size: 12px;
    }
    .lh-stat-string{
        font-size: 10px;
    }

    #lh-opp{
        font-size: 11px;
    }
    #lh-opp-date{
         font-size: 10px;
    }
    #container-fluid{
       padding: 0px;
    }
    /* #standing-week{
        display: none;
    } */
    #player_name_short{
        display: block;
    }
    #player_name{
        display: none;
    }

    #lh-standing-row{
        height: 40px;
    }
    /* .standing-table{
        display: inline-table;
    } */
    .padding-lr{
        padding: 0 1.25em;
    }

    #l-tl-div {
        font-size: 14px;
    }

    #lineup-lower-btm{
        padding-right: 0px;
    }
    .player-list-main-header{
        font-size: 12px;
    }

    #player-list-body{
        font-size: 11px
    }

    .lineup-table-img{
        width: 45px;
        padding-left: 0px;
    }
    /* tabs width to auto */
    .tabs{
        background: white;
        color:black;
        width: auto;
        display: table 
    }
  }
  /* League home media queries ends here */

  

  /* lineup media queries start here */

  @media only screen and (max-width: 1680px) {

    .hide-1680{
        display: none;
    }
    .lineup-stat-number{    
        font-size: 23px;
    }
    .lineup-stat{
        font-size: 18px;
    }

}

@media only screen and (max-width: 1520px) {

    #player-list-table  th {
        padding: 4px;
      } 
      #player-list-table  td {
        padding: 4px;
      } 

      #lineup-table  th {
        padding: 4px;
      } 
      #lineup-table  td {
        padding: 4px;
      }
      #l-tl-div{
        font-size: 14px;
    } 
}

@media only screen and (max-width: 1230px) {
    
    /* #player-list-div{
        display: none
    } */
    .col4{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col8{
        flex: 0 0 100%;
        max-width: 100%;
    }
    #submit-btn-small{
        display: inline-block;
    }
 
 }

@media only screen and (max-width: 992px) {
    
    #lineup-wrapper{
        margin-top: 0px;
    }
 
 }

 /* This will hide columns of player list so that it can be fit into smaller screens */
 @media only screen and (max-width: 830px) {
    
    .hide-830{
        display: none;
    }
    .height-830{
        height: 27px!important;
    }
    
 }
 /* for single player pane */
 @media only screen and (max-width: 800px) {
    
   .col6{
    flex: 0 0 100%;
    max-width: 100%;
   }
   .hide-800{
       display: none
   }
   .lineup-stat{
       font-size: 12px
   }
   .lineup-stat-number{
       font-size: 12px
   }
.lineup-table-img-big{
    height: 100px
    }
    #single-player-name{
        
        font-size: 14px;
    }
    #single-info{
        font-size: 12px
    }
    .padding-30{
        padding:4px
    }
    .mr-lr-3{
        margin-right: 10px
       }
    #btm-container{
        margin-top: 15px
    }
    .single-stats{
        font-size: 12px
    }
 }

 .padr-5{
  padding-right: 12px;
  font-size: 16px
 }

 @media only screen and (max-width: 705px) {
  .lg_type_img{
    width: 100px;
    height: 100px;
  }
}

 


 .rdt {
    position: relative;
  }
  .rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    border: 1px solid #f9f9f9;
  }
  .rdtOpen .rdtPicker {
    display: block;
  }
  .rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
  }
  
  .rdtPicker .rdtTimeToggle {
    text-align: center;
  }
  
  .rdtPicker table {
    width: 100%;
    margin: 0;
  }
  .rdtPicker td,
  .rdtPicker th {
    text-align: center;
    height: 28px;
  }
  .rdtPicker td {
    cursor: pointer;
  }
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
  }
  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: #999999;
  }
  .rdtPicker td.rdtToday {
    position: relative;
  }
  .rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #428bca;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
  }
  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  
  .rdtPicker td span.rdtOld {
    color: #999999;
  }
  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  .rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
  }
  .rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
  }
  .rdtPicker th.rdtSwitch {
    width: 100px;
  }
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
  }
  
  .rdtPrev span,
  .rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
  }
  
  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  .rdtPicker thead tr:first-child th {
    cursor: pointer;
  }
  .rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
  }
  
  .rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
  }
  
  .rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
  }
  .rdtPicker button:hover {
    background-color: #eee;
  }
  
  .rdtPicker thead button {
    width: 100%;
    height: 100%;
  }
  
  td.rdtMonth,
  td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
  }
  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: #eee;
  }
  
  .rdtCounters {
    display: inline-block;
  }
  
  .rdtCounters > div {
    float: left;
  }
  
  .rdtCounter {
    height: 100px;
  }
  
  .rdtCounter {
    width: 40px;
  }
  
  .rdtCounterSeparator {
    line-height: 100px;
  }
  
  .rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;
  
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
  }
  .rdtCounter .rdtBtn:hover {
    background: #eee;
  }
  .rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
  }
  
  .rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
  }
  
  .rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
  }
  
  .rdtTime td {
    cursor: default;
  }
  .vertical-middle{
    vertical-align: middle
  }

  .carousel-font{
    font-size: 0.69rem;
  }
  .red-bg{
    background: #f86c6b;
    color: white
  }
  .ml-3{
    margin-left: 3px
  }

  .bt-0{
    margin-bottom: 0;
  }

  .clockBox{
    margin: 5px 0;
    line-height: 18px;
    height: 134px;
    border: 5px solid rgb(39, 50, 56)
  }

  .pickDiv{
    font-size: 16px;
    margin: 7px 0
  }

  .currentTeam{
    font-size: 16px
  }

  .countdown{
    font-size: 26px;
    font-weight: bold;
    margin-top: 9px;
  }

  .roundInfo{
    font-size: 12px
  }

  .searchDiv{
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .playerDiv{
    flex: 0 0 75%;
    max-width: 75%;
  }

 #playerTableHead th{
   width: 14.28%;
 }

 .hidden { display:none; }

 .dollar{
   width: 100%;
	display:inline-block;
	position: relative;
	}
.dollar input{
	padding-left:15px;
	}
.dollar:before {
	position: absolute;
    content:"$";
    left:5px;
	top:7px;
}

.gpvqGX{
  background: rgb(39, 50, 56);
}
.sc-chPdSV{
  color: white
}
 .draftStarting{
  font-size: 20px;
  margin: 18px 0
 }

 .app-header .navbar-brand{
   width: 175px
 }

 .radio-button {
  position: relative;
  margin: 20px 0;
}

.radio-button input {
  position: absolute;
  margin: 5px;
  padding: 0;
  /* for mobile accessibility (iOS Label Bug) */
  visibility: hidden;
}
.radio-button .label-visible {
  margin-left: 2em;
  margin-bottom: 0;
}

.fake-radiobutton{
  position: absolute;
  display: block;
  top: 0;
  left: 3px;
  width: 20px;
  height: 20px;
  border: 1px solid slategray;
  background-color: white;
}
.fake-radiobutton:after {
  content: "";
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  background: #03F29E;
  transform: translateX(-50%) translateY(-50%);
}

.fake-radiobutton { border-radius: 50%; }
.fake-radiobutton:after { border-radius: 50%; }

input[type="radio"]:checked + span .fake-radiobutton:after { display: block; }


  /** Boorstrap extension **/

  @media (min-width: 768px) {
    .modal-xl {
      width: 90%;
     max-width:1200px;
    }
  }

  /* Media queries for season long draft */

  @media only screen and (max-width: 992px) {

    .app-header .navbar-brand{
      width: 155px
    }

    .helmet{
      width: 34px;
      display: block;
      margin: 0px auto;
      padding-top: 9px;
  }

  .sl-draft-player{
    width: 38px;
    display: block;
    margin: 0px auto;
    /* margin-top: 14px; */
  }

  .clockBox{
    margin: 5px 0;
    line-height: 7px;
    height: 92px;
    border: 5px solid rgb(39, 50, 56)
  }

  .pickDiv{
    font-size:9px;
    margin: 7px 0
  }

  .currentTeam{
    font-size: 9px
  }

  .countdown{
    font-size: 11px;
    font-weight: bold;
    margin-top: 9px;
  }
  .roundInfo{
    font-size: 8px
  }

  .draftStarting{
    font-size: 11px;
    margin: 18px 0
   }
}

@media only screen and (max-width: 1005px) {


  #desktopRow{
    display: none
  }

  .searchDiv{
    flex: 0 0 75%;
    max-width: 75%;
  }

  #rightBox{
    display: none;
  }

  .playerDiv{
    flex: 0 0 100%;
    max-width: 100%;
    font-size: 10px;
  }

  .carousel-font{
    font-size: 10px
  }

  .padr-5{
    padding-right: 12px;
    font-size: 11px
   }

   .draftBtn{
     font-size: 8px
   }

   
   
}

@media only screen and (max-width: 500px) {
  #playerTableHead td{
    padding: 6px
  }

  #playerTableHead th{
    padding: 6px
  }

  .playerNameElipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 70px
   }
}

@media only screen and (min-width: 1005px) {
  #inputRow{
    display: none
  }
}

.funds{
  text-align: right;
  float: right
}

.player_team_inp{
  margin-top: 34px
}

.chat_attach{
  width: 3%;
}

.chat_msg_inp{
  width: 97%;
}

#chat_menu{
  display: none;
}

/* lg screen media queries */

@media only screen and (max-width: 992px) {

  .carousel-inner {
    max-height: 90vh;
    border-radius: 0
  }

  .carousel.slide{
    margin-top: 0
  }

  .landingHeading{
    font-size: 30px
  }

  .mt-80{
    margin-top: 20px
  }
  .lp_btn{
    margin-bottom: 5%;
    display: block;
  }
  
  .funds{
      text-align: center;
      float: none;
  }

  .player_team_inp{
    margin-top: 0
  }

  .player_pos_filter{
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px
  }

  .chat_attach{
    width: 7%;
  }

  .chat_msg_inp{
    width: 93%;
  }

  #chat_menu{
    display: block
  }

  .hide-992{
    display: none
  }
.chat_mt_50{
  margin-top: 20px
}
.center_992{
  text-align: center
}

.carousel-content {
  position: absolute;
  bottom: 50%;
  left: 0;
  text-align: center;
  right: 0;
  z-index: 20;
  color: white;
  text-shadow: 0 1px 2px rgba(0,0,0,.6);
}

.slide1_heading{
  font-size: 30px
}

.slide1_sub_heading{
  font-size: 20px
}

.slide2_heading{
  font-size: 30px
}
.slide2_sub_heading{
  font-size: 20px
}

.carousel-caption {
  bottom: 5%;
  color: #03F29E;
  font-size: 20px;
  text-shadow: 0 1px 2px rgba(0,0,0,.6);

}

.carousel-btn{
  position: absolute;
  bottom: 38%;
  left: 0;
  text-align: center;
  right: 0; 
  z-index: 20;
  text-shadow: 0 1px 2px rgba(0,0,0,.6);
}

.carousel-btn button{
  width: 150px;
  font-size: 20px;
  text-shadow: 0 1px 2px rgba(0,0,0,.6);
}


.carousel-partner{
  position: absolute;
  bottom: 80%;
  left: 0;
  text-align: center;
  right: 0;
  z-index: 20;
  text-shadow: 0 1px 2px rgba(0,0,0,.6);  }

  
}

/* md screen media queries */

@media only screen and (max-width: 768px) {

  .hide-640{
    display: none
  }
  .setting_lg_info{
    text-align: right
  }
  .center_768{
    text-align: center
  }

  h1{
    font-size: 20px;
  }

  h3{
    font-size: 16px;
  }

  h2{
    font-size: 18px;
  }

  h4{
    font-size: 15px
  }

  h5{
    font-size: 14px
  }
  h6{
    font-size: 13px
  }
  .font-xs{
    font-size: 14px;
  }

  .default-btn{
    width: auto;
  }

  p{
    font-size: 12px;
  }

  span{
    font-size: 11px;
  }

  li{
    font-size: 12px;
  }

  a{
    font-size: 12px;
  }

  td{
    font-size: 12px;
  }

  .home_btn_box{
    text-align: center;
  }

}

@media only screen and (max-width: 576px) {

  .mt-55{
    margin-top: 0px
  }

  .chSumUser{
    font-size: 12px;
  }
  .chSumTime{
    font-size: 10px;
  }

  .hide-flex{
    display: none !important;
  }
  
  .show-flex{
    display: flex;
  }

  .chatImg{
    width: 35px;
  }

  .chatFont{
    font-size: 12px;
  }

  .chatImgRM{
    margin-right: 2vw;
  }

.mobCard{
  padding: 0;
  border: none;
}

  .box{
    padding: 10px;
  }

  .col1{
    flex: 0 0 1.33333%;
    max-width: 8%;
  }

  .hide-576{
    display: none
  }

  .hide-576-ib{
    display: none
  }

  .hide-576-td{
    display: none
  }

  .show-576-td{
    display: table-cell
  }

  .mt-dynamic{
    margin-top: 55px;
  }

  .mt-chat-dynamic{
    margin-top: 42px;
  }

  .show-576{
    display: block
  }

  .show-576-flex{
    display: flex;
  }

  .show-576-ib{
    display: inline-block;
  }

  .center_576{
    text-align: center;
  }
  .cardMargin{
    margin: auto
  }

  .lp_search{
    display: 'inline-block';
    width: "40%"
  }
  
  .lp_teams{
    display: "inline-block";
     width: "20%";
    margin-right: "18px"
  }
  
  .lp_stats{
    display: 'inline-block';
    width: "20%" 
  }

  .home_cch_btn{
    margin-top: 10px;
  }

  .home_importl_btn{
    margin: 0;
  }

  .import_l_back{
    width: 200px;
    margin: 0;
  }
  
  .import_l_next{
    width: 200px;
    margin-top: 10px
  }

  .contestIndexTop{
    margin-bottom: 20px;
    margin-top: 20px;
  }
}


/* DFS WEEKLY RADIO BUTTON CSS */

.dfs_btn {
  display: none;
}

.dfs_btn:not(:disabled) ~ label {
  cursor: pointer;
}

.dfs_btn:disabled ~ label {
  color: rgba(188, 194, 191, 1);
  border-color: rgba(188, 194, 191, 1);
  box-shadow: none;
  cursor: not-allowed;
}
.dfs_label {
  height: 100%;
  display: block;
  background: white;
  border: 2px solid #03F29E;
  border-radius: .25rem;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  box-shadow: 0px 3px 10px -2px rgba(161, 170, 166, 0.5);
  position: relative;
}
.dfs_btn:checked + label {
  background: #03F29E;
  color: rgba(255, 255, 255, 1);
  /* box-shadow: 0px 0px 20px #03F29E;
} */
}

.dfs_btn:checked + label::after {
  color: rgba(61, 63, 67, 1);
  font-family: FontAwesome;
  border: 2px solid #03F29E;
  content: "\F00C";
  font-size: 15px;
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  height: 25px;
  width: 25px;
  line-height: 22px;
  text-align: center;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.25);
}

.dfs_section {
  display: flex;
  flex-flow: row wrap;
}
.dfs_section > div {
  flex: 1;
  padding: 0.5rem;
}

@media only screen and (max-width: 700px) {
  .dfs_section {
    flex-direction: column;
  }
}

.switch-success .switch-input:checked + .switch-slider {
  background-color: #03F29E;
  border-color: #03F29E;
}

.h_300{
  max-height: 300px;
}

.h_600{
  max-height: 600px;
}


#searchclear {
  position: absolute;
  right: 35px;
  top: -7px;
  bottom: 0;
  height: 14px;
  margin: auto;
  font-size: 20px;
  cursor: pointer;
  color: #ccc;
}

.lp_search{
  display: 'inline-block';
  width: "60%"
}

.lp_teams{
  display: "inline-block";
   width: "18%";
  margin-right: "18px"
}

.lp_stats{
  display: 'inline-block';
  width: "20%" 
}

.circle-numb{
  font-weight: 800;
  color: #fff;
  font-size: 24px;
  transition: all 0.3s ease 0s;
  background: #03F29E;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  display: inline-block;
}

.halfOpacity{
  opacity: 0.5;
}

.flexRow{
  display: flex;
  flex-direction: row;
}

.flexCol{
  display: flex;
  flex-direction: column;
}

.justifyCenter{
  justify-content: center
}

.justifyAround{
  justify-content: space-around
}

.errorBorder{
  border: 2px solid #E60000;
}


/*League Settings Home Page */


/* Roster Settings Page */
.zPaddingBorder{
  padding: 0; 
  border-top: 1px solid #E5E6E7;
  padding-top: 1em;
}

.zPaddingRight{
  padding: 0;
  text-align: right; 
}

/* General League Settings Page */
.settings-button{
  background-color: #68C7A9;
  border: none;
  /* border: 1px solid #4C5358; */
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 50px;
  margin-top: 1em;
  margin-bottom: 1em;
}
.settings-button:hover{
  background-color: white;
  color: #68C7A9;
}

.circle-settings-button{
  background-color: #68C7A9;
  width: 36px;
  height: 36px;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 50px;
  margin-top: 1em;
  margin-bottom: 1em;
}
.circle-settings-button:hover{
  background-color: white;
  color: #68C7A9;
}

.scroll-list{
  overflow: auto;
  white-space: nowrap;
}
.scroll-list button {
  display: inline-block;
  color: white;
  text-align: center;
  /* padding: 14px; */
  text-decoration: none;
  margin-right: 1em;
}

/* Settings */
.save-settings-btn{
    background-color: white;
    color: #03F29E;
    font-size: 16px;
    font-weight: 600;
    border:none;
}

.Toastify__toast-container {
  z-index: 9999;
  position: fixed;
  padding: 4px;
  max-width: 450px;
  width: 100%;
  box-sizing: border-box;
  color: #fff;
}

.react-autosuggest__suggestions-container--open{
  display: block;
  position: absolute;
  top: 39px;
  width: 280px;
  border: 1px solid #6c757d;
  background-color: #343434;
  /* font-family: Helvetica, sans-serif; */
  /* font-weight: 300; */
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.ht-center{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.sticky-table-table{
  width: 100%;
}

.selectedLeague{
  border-left: 3px solid white;
  background-color: #1D2226;
}

.bg400{
  background-color: #121212 !important;
}

.greyText{
  color: rgba(255,255,255,0.65);
}

.successBtn{
  min-width: 200px;
  background: rgba(3, 242, 158, 0.1);
  border: 1px solid #03F29E;
  border-radius: 6px;
  color: #03F29E;
}

.successBtn:hover{
  min-width: 200px;
  background: rgba(3, 242, 158, 0.1);
  border: 1px solid #03F29E;
  border-radius: 6px;
  color: #03F29E;
  opacity: 0.8;
}

.divider{
  border: 1px solid rgba(102,102,102,0.25);
  margin: 5px 0;
}

.flexGrow{
  flex-grow: 1;
}

.scrollHidden{
  overflow: hidden !important;
}

.flexWrap{
  flex-wrap: wrap;
}

.min-w-0{
  min-width: 0;
}

.min-h-0{
  min-height: 0 !important;
}

.progress-bar {
  background-color: #03F29E;
  color: black;
}
.date-separator{
  display: flex;
  align-items: center;
}
.date-separator .line{
  flex: 1;
  height: 1px;
  background-color: #36373B;
}

.fadeInImage{
  transition: all 0.2s linear;
}

.Overlay {
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
  z-index: 5;
}

.Overlay.Show {
  display: block;
}

.Modal {
  position: fixed;
  bottom: -150vh;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
  left: 0;
  /* padding: 0 12px 12px; */
  transition: all 0.3s ease-out;
  z-index: 10;
}

.Modal.Show {
  bottom: 0;
}

/* V2 CLASSES */
.infoText{
  color: #848484 !important;
}
.basicText{
  color: #484c4e !important;
}
.primaryText{
  color: #1A1C20 !important
}
.whiteText{
  color: #ffff !important
}
.scroll{
  overflow: auto !important;
}
.Toastify__toast--success {
  background-color: #03F29E;
  color: #1A1C20
}
.Toastify__close-button{
  color: #1A1C20;
  font-weight: bold;
}

.darkerBackdrop{
  background-color: "red" !important;
  /* background-color: black; */
  /* opacity: 1; */
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.roboto{
  font-family: 'Roboto' !important;
}

@font-face {
  font-family: 'Digital Numbers';
  font-style: normal;
  font-weight: normal;
  src: local('Digital Numbers'), url('./fonts/DigitalNumbers-Regular.woff') format('woff');
}

.font-roboto-condensed {
  font-family: 'Roboto Condensed', sans-serif;
}

.font-digital-numbers { 
  font-family: 'Digital Numbers';
}


/* Perfect Scrollbar customizations START */

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .p__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x .ps--clicking,
.ps .ps__rail-y .ps--clicking {
  background-color: transparent !important;
}

.ps .ps__thumb-y,
.ps .ps__thumb-x,
.ps .ps__thumb-y:focus,
.ps .ps__thumb-x:focus,
.ps .ps__thumb-y .ps--clicking,
.ps .ps__thumb-x .ps--clicking {
  background-color: #03F29E20 !important;
}
/* Perfect Scrollbar customizations END */


/* Custom Scrollbar like MacOS */
/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */
.scrollbar {
  overflow: overlay
}

/* total width */
.scrollbar::-webkit-scrollbar {
  background-color: rgba(0,0,0,0);
  width: 8px;
  z-index: 999999;
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(0,0,0,0);
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0);
  border-radius:8px;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display:none;
}


/* scrollbar when element is hovered */
.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #03F29E20;
}

/* scrollbar when scrollbar is hovered */
.scrollbar::-webkit-scrollbar-thumb:hover {
    background-color:#03F29E20;
}

/* Firefox scrollbar customizations */
.ff-scrollbar {
  scrollbar-color: #03F29E20 transparent;
  scrollbar-width: thin;
}

/* Font sizes */

.heading-1 {
  font-size: 2rem !important;
}

.heading-2 {
  font-size: 1.5rem !important;
}

.big-font {
  font-size: 1rem !important;
}

.small-font {
  font-size: 0.875rem !important;
}

.sub-font {
  font-size: 0.75rem !important; 
}

/* Letter Spacing */

.letter-spacing-2 {
  letter-spacing: 2px !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.modal {
  background: rgba( 0, 0, 0, 0.3 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

input[type="date"]
{
    min-width: 96%;
}

.alex{
  font-family: "Alexandria";
}

.modalBackdropFilter {
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
}

.customModal {
  max-width: 560px;
  max-height: 650px;
}