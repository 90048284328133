.login-main-section {
    width: 100%;
  }
  .login {
    margin: 30px 0px 30px 0px
  }
  .line-section h5 {
    overflow: hidden;
    text-align: center;
  }
  
  .line-section h5:before,
  .line-section h5:after {
    background-color: grey;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  
  .line-section h5:before {
    right: 0.5em;
    margin-left: -50%;
  }
  
  .line-section h5:after {
    left: 0.5em;
    margin-right: -50%;
  }
  .login-section {
    /* margin-top: 30px */
  }
  .login-inputs {
    margin-top: 10px;
  }
  .forget-pass {
    margin: 10px 0px 0px 0px;
  }
  .forget-text {
    color: #73B8F0;
    margin-top: 8px;
  }
  .input-text-logo {
    margin-top: 10px;
    width: 47px
  }
  

input[type=checkbox] {
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}

@media screen and (min-width: 992px) {
  .login-width-section {
    max-width: 70%
  }
}

@media screen and (max-width: 992px) {
  .login-span {
    margin-top: 3px
  }
}

.login-head-container{
  border-bottom: 1px solid #333333;
}

.welcome-heading{
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  /* text-align: center; */
}