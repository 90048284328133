/*Vertical Steps*/
.list-group.vertical-steps{
  padding-left:10px;
}
.list-group.vertical-steps .list-group-item{
  border:none;
  border-left:1px solid #ece5dd;
  box-sizing:border-box;
  border-radius:0;
  /* counter-increment: step-counter; */
  padding-left:21px;
  padding-right:0px;
  padding-bottom:20px;  
  padding-top:0px;
}
.list-group.vertical-steps .list-group-item.active{
  background-color:transparent;
  color:inherit;
}
.list-group.vertical-steps .list-group-item:last-child{
  border-left:3px solid transparent;
  padding-bottom:0;
}
.list-group.vertical-steps .list-group-item::before {
  border-radius: 50%;
  background-color:#ece5dd;
  color:#184C3E;
  border: 2px solid #184C3E;
  content: " ";
  display:inline-block;
  float:left;
  height:25px;
  line-height:25px;
  margin-left:-35px;
  text-align:center;  
  width:25px;  
}
.list-group.vertical-steps .list-group-item span,
.list-group.vertical-steps .list-group-item a{
  display:block;
  overflow:hidden;
  padding-top:2px;
}

/*Active/ Completed States*/
.list-group.vertical-steps .list-group-item.active::before{
  background-color:#fff;
  color:#fff;
}
.list-group.vertical-steps .list-group-item.completed{
  border-left:2px solid #184C3E;
}
.list-group.vertical-steps .list-group-item.completed::before{
  background-color:#0CA974;
  color:#fff;
}
.list-group.vertical-steps .list-group-item.completed:last-child{
  border-left:3px solid transparent;
}

.font16{
  font-size: 16px;
}
.teamName{
  font-size: 12px;
}

.greyText{
  color: #999999;
}
.mlbOpp{
  color: #EFA4A0
}

@media only screen and (max-width: 640px) {
    
  .teamName{
    font-size: 10px;
  }
  .font16{
    font-size: 12px;
  }
}