[contenteditable][placeholder]:empty:before {
    content: attr(placeholder);
    position: absolute;
    color: gray;
    background-color: transparent;
  }

[contenteditable]:active,
[contenteditable]:focus{
    outline: 0px;
}

.richtext-icon{

}

.richtext-icon:hover{
    background-color: dimgray;
    border-radius: 10%;
}

.dull-color {
    opacity: 0.5;
}

.dull-color:focus-within{
    opacity: 1.0;
}