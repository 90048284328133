.image-contain {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .image-contain.top {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .div-block-5 {
    position: relative;
    width: 437px;
    height: 550px;
    background-image: url('../assets/images/iPhone-Frame.png');
    background-position: 50% 10%;
    background-size: 600px;
    z-index: 1;
    margin-top: -40px
  }

  .sm-div-block-5 {
    position: relative;
    width: 180px;
    height: 300px;
    background-image: url('../assets/images/iPhone-Frame.png');
    background-position: 50% -3%;
    background-size: 310px;
    z-index: 1
  }
  
  .w-background-video {
    position: relative;
    overflow: hidden;
    color: white;
  }

  .w-background-height {
    height: 500px;
  }

  .sm-w-background-height {
    height: 290px;
  }

  .w-background-video > video {
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    object-fit: cover;
  }
  .w-background-video > video::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
  }
  .w-background-video--control {
    position: absolute;
    bottom: 1em;
    right: 1em;
    background-color: transparent;
    padding: 0;
  }
  .w-background-video--control > [hidden] {
    display: none !important;
  }

  .background-video-2 {
    position: absolute;
    top: 30px;
    width: 320px;
    height: 700px;
  }

  .sm-background-video-2 {
    position: absolute;
    top: 75px;
    /* width: 200px; */
    height: 400px;
  }

  .footerLinks {
    text-decoration: underline;
  }

  .footerLinks:hover {
    text-decoration: none;
  }