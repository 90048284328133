#message-actions {
    display: none;
}

#message:hover{
        background-color: #1D2226;
        /* #message-actions{
            display: none;
        } */
}

#message:hover #message-actions {
    display: block;
}